// section-advantages styles
.o-section-advantages{
    display: inline-flex;
    justify-content: center;
    width: 100%;
    &__container{
        display: inline-flex;
        flex-direction: column;
        align-items: center;
    }
    &__title{   
        padding-top: 5vh;
        font-size: $fs-30;
        font-weight: $fw-bold;
        letter-spacing: 1.5px;
        color: $c-grey-2;
        text-align: center;
        & span{
            text-transform: uppercase;
        }
        @include media('<screen') {
            font-size: $fs-24;
            
        }
        @include media('<tablet') {
            font-size: $fs-18;
            
        }
    }
}
