// item-process styles
.m-item-process{
  &__items{
      display: inline-flex;
      flex-wrap: wrap;
  }
  &__item{
      padding-top: 3vh;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: relative;
  }
  &__img{
    height: 160px;
    width: 160px;
    border: solid 2px $c-violet;
    @include backgroundCoverCenter;
    border-radius: 100%;
    position: relative;
    z-index: 1;
    @include media('<screen') {
        height: 120px;
        width: 120px;
    }
    @include media('<desktop') {
        width: 90px;
        height: 90px;
    }
    @include media('<maxPhone') {
        width: 120px;
        height: 120px;
    }
  }
  &__number{
    align-self: flex-start;
    font-family: $f-bickham;
    font-size:$fs-60;
    color: $c-violet;
    line-height: $lh-0-5;
    margin-top: -40px;
    padding-left: 35px;
    @include media('<screen') {
      font-size: $fs-50;
      margin-top: -30px;
    }
    @include media('<desktop') {
      font-size: $fs-40;
      margin-top: -25px;
      padding-left: 20px;
    }
    @include media('<maxPhone') {
      margin-left: 30px;
    }
  }
  &__subtitle{
    font-size:$fs-16;
    hyphens: auto;
    line-height: $lh-1-5;
    letter-spacing: 0.8px;
    color: $c-violet;
    width: 90%;
    @include media('<screen') {
      font-size: $fs-14;
      width: 95%;
    }
    @include media('<desktop') {
      font-size: $fs-13;
      width: 100%;
    }
  }
}