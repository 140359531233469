html {
    -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}

* {
    box-sizing: border-box;
}

body {
  background: $c-white-3;
  color: $c-black-2;
  font-family:$f-drugs;
  font-size: $fs-16;
  overflow-x: hidden;
}
h1 {
  margin-top: 40px;
}
h2{
  margin: 0;
}
.l-content{
  // padding-top: 100px;
  padding-top: 75px;
  @include media('<screen') {
    padding-top: 0;
  }
}
.l-violet{
  background-color: $c-violet;
}
.l-select{
  user-select: none;
}

.col-phone{
  @include media('<phone') {
    max-width: 250px !important;
    flex: 0 0 250px !important;
    margin: 0 auto;
  }

}

.slick-slide{
  outline: none !important;
}

section#section-intro,
section#section-process,
section#section-advantages,
section#section-factors,
section#section-slider,
section#section-reviews,
section#section-footer,
section#section-footer .fp-tableCell,
section#section-reviews .fp-tableCell,
section#section-slider .fp-tableCell,
section#section-factors .fp-tableCell,
section#section-intro .fp-tableCell,
section#section-advantages .fp-tableCell,
section#section-process .fp-tableCell{
  @include media('<screen') {
    height: auto !important;
  }
  @include media('height<tablet') {
    height: auto !important;
  }
}
//   .icon {
//     animation: icon .25s infinite alternate;
//   }
//   .svg-image {
//     width: 100%;
//     background: $c-white;
//     padding: 40px;
//     margin-top: 50px;
//   }
//   @keyframes icon {
//     0% {
//       transform: translateY(0px)
//     }
//     100% {
//       transform: translateY(5px)
//     }
//   }