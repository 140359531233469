// section-reviews styles
.o-section-reviews{
  overflow: hidden;
  
  &__container{
    height: 100%;
    padding: 10vh 0 0;
    @include media('<screen') {
      padding-top: 30px;
    }
  }
  &__title{
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-size: $fs-30;
    letter-spacing: 1.5px;
    color: $c-violet;
    font-weight: $fw-bold;
    @include media('<screen') {
      font-size: $fs-24;
    }
    @include media('<tablet') {
      font-size: $fs-18;
    }
  }
  &__items{
    font-weight: $fw-bold;    
    position: relative;
    margin: 15vh 0;
    @include media('<screen') {
      margin: 30px 0;
    }
  }
}