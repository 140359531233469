// item-advantage styles
.m-item-advantage{
  &__items{
    display: inline-flex;
    flex-wrap: wrap;
    padding-top: 5vh;
    @include media('<screen') {
      padding-top: 15px;
    }
  }
  &__item{
    padding-top: 5vh;
    padding-bottom: 5vh;
    display: inline-flex;
    flex-direction: column;
    align-items: center;

  }
  &__subtitle{
    padding-top: 4vh;
    font-size: $fs-18;
    line-height: $lh-1-3;
    text-align: center;
    color: $c-white-10;
    width: 75%;
    letter-spacing: 1.1px;
    @include media('<screen') {
      font-size: $fs-16;
    }
    @include media('<desktop') {
      font-size: $fs-13;
      width: 85%;
    }
  }
  &__img{
    height: 10vh;
    width: auto;
  }
}