// item-slider styles
.m-item-slider{
  position: relative;
  z-index: 0;
  margin: auto 0;
  &__item{
    height:100%;
    width: 100%;
    align-items: center;
  }
  &__img{
    display: inline-flex;
    height:55vh;
    width: 100%;
    @include backgroundCoverCenter;
    z-index: 1;
    @include media('<screen') {
      height: 490px;
    }
    @include media('<desktop') {
      height: 440px;
    }
    @include media('<tablet') {
      height: 340px;
    }
    @include media('<maxPhone') {
      height: 300px;
    }
    @include media('<phone') {
      height: 250px;
    }
    @include media('<iPhone') {
      height: 200px;
    }
  }
  &__content{
    @include absolutelyFull;
    display: inline-flex;
    align-items: center;
  }
}