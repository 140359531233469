// item-review styles
.m-item-review{
  position: relative;
  z-index: 0;
  margin: auto 0;
  &__item{
    height:100%;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__img{
    display: inline-flex;
    height:225px;
    width: 225px;
    border-radius: 100%;
    z-index: 1;
    @include backgroundCoverCenter;
    @include media('<tablet') {
      height:150px;
      width: 150px;
    }
  }
  &__text{
    width: 75%;
    padding-top: 7%;
    font-size: $fs-20;
    color: $c-violet;
    text-align: justify;
    @include media('<screen') {
      font-size: $fs-18;
    }
    @include media('<tablet') {
      font-size: $fs-14;
    }
    @include media('<phone') {
      padding-top: 10%;
      width: 90%;
    }
  }
}