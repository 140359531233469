// input styles
.a-input {
  padding: 10px;
  background-color: transparent;
  outline: none;
  border: 2px solid $c-white-3;
  color: $c-white;
  font-family: $f-OpenSans;
  transition: border-color $t-0-35;
  &:focus{
    border-color: $c-violet-2;
    
  }
}