// section-footer styles
.o-section-footer{
  // height: calc( 100vh - 75px);
  &__wrap{
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    @include media('<tablet') {
      flex-direction: column-reverse;
    }
  }
  &__container{
    display: inline-flex;
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    // height: 65vh;
    // padding-bottom: 5vh;
    height: 70%;
    @include media('<screen') {
      height: auto;
      padding-bottom: 20px;
      
    }

  }
  &__contact{
    // position: absolute;
    // top: 35vh;
    // right: 47%;
    // align-self: center;
    // df
    @include media('<tablet') {
      order: 2;
      justify-content: center;
      // padding-left: 15px;
      // padding-right: 15px;
      padding-top: 30px;
    }
    @include media('<phone') {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  &__form{
    @include media('<tablet') {
      order: 1;
      align-items: center;
      padding-left: 15px;
      
    }
  }
  &__map{
    height: 30%;
    width: 100%;
    padding: 0;
    border: 0;
    @include media('<screen') {
      height: 400px;
      
    }
  }
}