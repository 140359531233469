// textarea styles
.a-textarea{
    padding: 10px;
    background-color: transparent;
    outline: none;
    border: 2px solid $c-white-3;
    color: $c-white;
    font-family: $f-OpenSans;
    transition: border-color $t-0-35;
    height: 20vh;
    resize: none;
    &:focus{
        border-color: $c-violet-2;
    }
    @include media('<screen') {
        height: 170px;
    }
    @include media('<desktop') {
        height: 150px;
    }
}