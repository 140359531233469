// contact styles
.m-contact{
    border-radius: 25px;
    color: $c-grey-3;
    padding: 0 50px 2vh;
    display: inline-flex;
    justify-content: flex-end;
    // @include media('<large') {
    //     max-width: 350px;
    //     padding: 5vh 30px 2vh;    // }
    @include media('<screen') {
        padding-bottom: 0px;
    }
    &__title{
        text-transform: uppercase;
        letter-spacing: 1.5px;
        font-size: $fs-26;
        color: $c-grey;
        font-weight: $fw-bold;
        width: 100%;
        text-align: center;
        padding-top: 2vh;
        @include media('<screen') {
            font-size: $fs-24;
          }
      }
    &__content{
        max-width: 475px;
        display: inline-flex;
        flex-direction: column;
    }
    &__text{
        padding-top: 4vh;
        font-weight: $fw-bold;
        font-size: $fs-16;
        @include media('<desktop') {
            font-size: $fs-13;
        }
    }
    &__icon{
        height: 30px;
        max-width: 30px;
        width: 100%;
        fill: $c-grey-3;
        margin-right: 30px;
    }
    &__address, &__phone {
        margin-left: 30px;
        display: inline-flex;
        align-items: center;
        font-weight: $fw-bold;
        font-size: $fs-18;
        max-width: 300px;
        padding: 2vh 0;
        @include media('<desktop') {
            font-size: $fs-14;
        }
    }
    &__social{
        // width: 100%;
        margin-left: 30px;
        display: inline-flex;
        font-weight: $fw-bold;
        font-size: $fs-18;
        max-width: 300px;
        @include media('<desktop') {
            font-size: $fs-13;
        }
    }
    &__phone{
        &-items{
            display: inline-flex;
            flex-direction: column;
        }
        &-item{
            color: $c-grey-3;
        }
    }

}