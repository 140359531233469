// menu styles
.m-menu {
  @include media('<tablet') {
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    top: 61px;
    right: -1px;
    border: 1px solid $c-violet;
    height:calc( 100vh - 74px);
    transform: translateX(100%);
    transition: transform $t-0-35;
    z-index: 1;
    background-color: $c-white-3;
    width: 150px;
  }
  @include media('<maxPhone') {
    top: 54px;
    height:calc( 100vh - 59px);
  }
  &--active{
    @include media('<tablet') {
      transform: translateX(0%);
    }
  }
  &__overflow{
    top: 62px;
    position: absolute;
    height: 100vh;
    width: 100%;
    left: -1px;
    background-color: rgba($c-black, $alpha: .5);
    display: none;
    opacity: 0;
    transition: opacity $t-0-35;
    @include media('<maxPhone') {
      top: 55px;
    }
    &--active{
      @include media('<tablet') {
        display: block;
        opacity: 1;
      }
    }
  }
  &__item {
    color: $c-violet;
    display: inline-flex;
    padding: 5px 12px;
    position: relative;
    @include media('<tablet') {
      max-width: 150px;
    }
    &:after{
      position: absolute;
      margin: 0 auto;
      content: '';
      bottom: 4px;
      width: 0;
      left:  0;
      right: 0;
      height: 2px;
      background-color: $c-violet;
      transition: width $t-0-35;
      border-radius: 3px;
    }
    &:hover{
      &:after{
        width: calc(100% - 24px);
      }
    }
  }
}