// modal-window styles
.m-modal-window{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: $fs-12;
    color: $c-grey-3;
    border-radius: 7px;
    background-color: $c-violet;
    font-weight: $fw-bold;
    text-transform: uppercase;
    user-select: none;
    // overflow: hidden;
    cursor: pointer;
    &--active{
        background-color: transparent;
        border: 2px solid $c-violet;
        margin-right: -35px;
        & .m-modal-window__text{
            display: none;
        }
        & .m-modal-window__form{
            display: inline-flex;
        }
    }
    &--send{
        margin-right: 19px;
        & .m-modal-window__text{
            display: none;
        }
        & .m-modal-window__send{
            display: block;
        }
        & .m-modal-window__form{
            display: inline-flex;
        }
        & .m-modal-window__icon--airplane{
            display: none;
        }
        & .m-modal-window__icon--check{
            display: block;
        }
        & .m-modal-window__input{
            display: none;
        }
    }
    &__text{
        width: 151px;
        padding: 10px;
        transition: all $t-0-35;
        
    }
    &__send{
        display: none;
        width: 95px;
        padding: 10px;
        transition: all $t-0-35;
        
    }
    &__form{
        // display: inline-flex;
        // &--hidden{
            display: none;
            background-color: transparent;
            position: relative;
        
    }
    label{
        position: absolute;
        left: 0;
        top: -20px;
        color: lightcoral;
        text-transform: lowercase;
        font-family: $f-OpenSans;
    }
    &__input{
        color: $c-violet;
        border: none;
        font-size: $fs-14;
        width: 145px;
        transition: all $t-0-35;
        
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $c-violet;
          }
          &::-moz-placeholder { /* Firefox 19+ */
            color: $c-violet;
          }
          &:-ms-input-placeholder { /* IE 10+ */
            color: $c-violet;
          }
          &:-moz-placeholder { /* Firefox 18- */
            color: $c-violet;
          }
    }
    &__button{
        background-color: $c-violet;
        -webkit-appearance: none;
        -moz-appearance:    none;
        appearance: none;
        outline: none;
        overflow: hidden;
        border:none;
        cursor: pointer;
    }
    &__icon{
        fill:$c-white;
        &--airplane{
            display: block;
        }
        &--check{
            display: none;
        }
    }
}