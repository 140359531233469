// button-hamburger styles
.a-hamburger {
  position: relative;
  width: 25px;
  height: 20px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
  &__line {
    transition: transform $t-0-35;
    height: 2px;
    background: $c-violet;
    width: 100%;
  }
  &--active{
    justify-content:center;
    
    & .a-hamburger__line{
      position: absolute;
      left: 0;top: 10px;
      &:nth-child(1) {
        transform: rotate(45deg)
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        transform: rotate(-45deg)
      }
    }
  }
}