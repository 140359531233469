@mixin backgroundContainCenter {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

@mixin backgroundCoverCenter {
  background-size: cover;
  background-position: center center;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
@mixin absolutely {
  position: absolute;
  left: 0;
  top: 0;
}
@mixin absolutelyCenter {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
@mixin absolutelyFull {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@mixin limitText($lines, $lineHeight) {
   overflow: hidden;
   text-overflow: ellipsis;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: $lines;
   line-height: $lineHeight;
	 max-height: $lineHeight * $lines + em;
}



//------------------------------
// Font face mixin
//------------------------------
@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot /*woff2*/ woff ttf ) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}