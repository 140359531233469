// logo styles
.a-logo{
    height: 65px;
    width: 160px;
    @include media('<desktop') {
        height: 50px;
        width: 135px;
    }
    @include media('<phone') {
        height: 50px;
        width: 115px;
    }
}


.st0{fill:$c-violet;}
.st1{fill:$c-violet;}
.st2{fill:#FFFFFF;}
.st3{fill:#ED1C24;}
.st4{fill:#009649;}
.st5{fill:#231F20;}
.st6{fill:url(#GradientFill_2);}
.st7{fill:#991F23;}
.st8{fill:#00A651;}
