// slider-arrows styles
.m-slider-arrows{

    &__arrow{
        width: 45px;
        height: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        @include absolutely;
        transition: transform $t-0-35;
        cursor: pointer;
        z-index: 5;
        @include media('<maxPhone') {
            width: 35px;
        }
        &.visible{
            transform: translateX(0%)
        }
        &:hover{
            & .m-slider-arrows__wrapper{
                opacity: 1;
            }
        }
    }
    &__prev{
        transform: translateX(-100%);
        @include media('<maxPhone') {
            &.visible{
                transform: translateX(15px)
            }
        }
    }
    &__next{
        left: auto;
        right: 0;
        transform: translateX(100%);
        @include media('<maxPhone') {
            &.visible{
                transform: translateX(-15px)
            }
        }
    }
    &--violet{
        & .m-slider-arrows__wrapper{
            border: 2px solid $c-violet;
            background-color:rgba($c-white, $alpha: 0.5);
            
        }
        & .m-slider-arrows__icon{
            fill:$c-violet;
        }
    }
    &__wrapper{
        background-color:rgba($c-black, $alpha: 0.5);
        border-radius: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        border: 2px solid $c-white-3;
        opacity: .5;
        transition: opacity $t-0-35;
        @include media('<maxPhone') {
            height: 30px;
            width: 30px;
        }
    }
    &__icon{
        height: 20px;
        fill:$c-white-3;
        width: 16px;
    }
}
