// section-process styles
.o-section-process{
    &__container{
        height: 100%;
        // padding: 10vh 0 0
        @include media('<screen') {
            padding: 10px 0 30px;
        }
    }
    &__items{
        // font-family: $f-lato;
        height: inherit;
        display: inline-flex;
        align-items: center;
        // font-family: "TT Drugs";
        font-weight: 900;
    }

}