// social styles
.m-social{
    flex-direction: column;
    &__link{
        padding: 2vh 0;
        display: inline-flex;
        cursor: pointer;
        color: $c-grey-3;
        align-items: center;
        
    }
    &__img{
        height: 30px;
        width: 30px;
        margin-right: 30px;
    }
}