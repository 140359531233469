// button styles
.a-button{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: $fs-12;
    color: $c-grey-3;
    border-radius: 7px;
    background-color: $c-violet;
    font-weight: $fw-bold;
    text-transform: uppercase;
    padding: 10px;
}