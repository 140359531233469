// intro-slider styles
.m-intro-slider{
    position: relative;
    z-index: 0;
    &__item{
        height:100%;
        width: 100%;
        max-width: 1920px;
        display: inline-flex;
        align-items: center;
    }
    &__img{
        height: calc(100vh - 75px);
        margin-top: 75px;
        width: 100%;
        @include backgroundCoverCenter;
        z-index: 1;
        @include media('<screen') {
            height: 525px;
        }
        @include media('<desktop') {
            height: 450px;
        }
        @include media('<tablet') {
           height: 350px;
        }
        @include media('<maxPhone') {
            height: 250px;
            margin-top: 60px;
        }
        @include media('<phone') {
            height: 200px;
        }
    }
    &__content{
        @include absolutelyFull;
        display: inline-flex;
        align-items: center;
        @include media('<tablet') {
            padding-top: 75px;
        }
        @include media('<maxPhone') {
            padding-top: 60px;
        }
    }
    &__title{
        position: relative;
        z-index: 2;
        display: inline-flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        font-size: $fs-36;
        color: $c-grey;
        letter-spacing: 1.8px;
        padding: 35px 0;
        background-color: rgba($c-black, $alpha: .55);
        & span{
            font-family: $f-OpenSans-b;
        }
        @include media('<desktop') {
            font-size: $fs-26;
        }
        @include media('<tablet') {
            font-size: $fs-22;
            padding: 25px 0;
        }
        @include media('<maxPhone') {
            font-size: $fs-18;
            padding: 15px 0;
        }
        @include media('<phone') {
            font-size: $fs-16;
            padding: 10px 0;
        }
    }
}