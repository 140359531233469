// item-factor styles
.m-item-factor{
  height: 405px;
  width: 405px;
  @include backgroundContainCenter;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include absolutelyCenter;
  @include media('<screen') {
    height: 350px;
    width: 350px;
  }
  @include media('<tablet') {
    height: 300px;
    width: 300px;
  }
  &__items{
    height: 285px;
    width: 285px;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    @include media('<screen') {
      height: 235px;
      width: 235px;
    }
    @include media('<tablet') {
      height: 220px;
      width: 220px;
    }
  }
  &__item{
    height: 285px;
    width: 285px;
    @include absolutely;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    overflow: hidden;
    border: 4px solid $c-violet;
    @include media('<screen') {
      height: 235px;
      width: 235px;
    }
    @include media('<tablet') {
      height: 220px;
      width: 220px;
      border: 3px solid $c-violet;

    }
  }
  &__img{
    height: 285px;
    width: 285px;
    @include backgroundCoverCenter;
    @include absolutelyCenter;
    z-index: 0;
    @include media('<screen') {
      height: 235px;
      width: 235px;
    }
    @include media('<tablet') {
      height: 220px;
      width: 220px;
    }
  }
  &__text{
    z-index: 1;
    background-color:rgba($c-white, $alpha: .75);
    width: 100%;
    font-size: $fs-16;
    padding: 10px 0;
    text-align: center;
    font-weight: $fw-bold;
    color: $c-violet;
    @include media('<tablet') {
      font-size: $fs-14;
    }
  }
}