// section-factors styles
.o-section-factors{
  display: inline-flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  &__title{
    padding-top: 5vh;
    font-size: $fs-30;
    font-weight: $fw-bold;
    letter-spacing: 1.5px;
    color: $c-violet;
    text-align: center;
    text-transform: uppercase;
    @include media('<screen') {
      font-size: $fs-24;
    }
    @include media('<tablet') {
      font-size: $fs-18;
    }
  }
  &__container{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    @include media('<screen') {
      padding: 0 0 30px;
    }
  }
  &__wrap{
    width: 700px;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    @include media('<screen') {
      height: auto;
      width: 600px;
    }
    @include media('height<tablet') {

      height: 100%;
    }
    @include media('<tablet') {
      width: 500px;
    }
    @include media('<maxPhone') {
      height: 330px;
    }
  }
}