// section-slider styles
.o-section-slider{
  overflow: hidden;
 
  &__container{
    height: 100%;
    padding: 10vh 0 0;
    @include media('<screen') {
      padding-top: 30px;
    }
    // margin: 0 auto;
  }
  &__title{
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-size: $fs-30;
    letter-spacing: 1.5px;
    color: $c-white-4;
    & span{
      font-weight: $fw-bold;
    }
    @include media('<screen') {
      font-size: $fs-24;
    }
    @include media('<tablet') {
      font-size: $fs-18;
    }
  }
  &__items{
      // font-family: $f-lato;
      // height: inherit;
      // display: inline-flex;
      // align-items: center;
      position: relative;
      margin: 10vh 0;
      // width: 100%;
      @include media('<screen') {
        margin: 30px auto 50px;
        width: 80%;
      }
      @include media('<desktop') {
        width: 100%;
      }
      @include media('<maxPhone') {
        padding: 0 15px;
      }
    }

}