// header styles
.o-header{
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: $c-white-3;
    border: solid 1px $c-violet;
    height: 75px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    font-weight: $fw-bold;
    font-family: $f-OpenSans-b;
    @include media('<maxPhone') {
    height: 60px;
    }
    &__modal-window{
        @include media('<screen') {
            display: none;
        }
    }
    &__hamburger{
        display: none;
        @include media('<tablet') {
            display: inline-flex;
        }
    }
    &__container{
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        @include media('<screen') {
            width: 100%;
        }
    }
    &__item{
        display: inline-flex;
        
        align-items: center;
        @include media('<screen') {
            justify-content: center !important;
            
        }
        @include media('<tablet') {
            justify-content: flex-start !important;
        }
        &:first-child{

            @include media('<iPhone') {
                padding: 0 0 0 10px;
            }
        }
        &:last-child{

            @include media('<tablet') {
                justify-content: flex-end !important;
                position: static;
            }
        }
    }
    &__phones{
        display: inline-flex;
        flex-direction: column;
        font-size: $fs-16;
        line-height: $lh-1-7;
        @include media('<desktop') {
            font-size: $fs-13;
            padding-left: 0;
        }
        @include media('<tablet') {
            flex-direction: row;
        }
        @include media('<maxPhone') {
            flex-direction: column;
        }
        @include media('<iPhone') {
            padding: 0px;
    
            
        }
  
    }
    &__phone{
    padding-left: 30px;
    @include media('<phone') {
        padding-left: 15px;
        font-size: $fs-12;
        font-family: $f-OpenSans;
        
    }
    @include media('<iPhone') {
        padding-left: 5px;

        
    }
    }
    &__menu{
        font-size: $fs-14;
        @include media('<desktop') {
            font-size: $fs-13;
        }
    }
    &__button{
        margin: 0 12px;
    }
}