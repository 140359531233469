// form styles
.m-form{
  display: inline-flex;
  flex-direction: column;
  padding-left: 50px;
  padding-bottom: 2vh;
  // justify-content: center;
  
  @include media('<screen') {
    padding-bottom: 0px;
  }
  &__thanks{
    color: $c-grey;
    font-weight: $fw-bold;
    text-align: center;
    font-size: $fs-24;
    display: none;
    align-items: center;
    height: 75%;
    opacity: 0;
    transition: opacity $t-0-5;
    &--visible{
      opacity:1;
      display: inline-flex;
    }
  }
  & label{
    color: $c-grey;
    transform: translateY(-50%);
    height: 2vh;
    font-size: $fs-14;
    display: block !important;
    color: lightcoral;
    font-family: $f-OpenSans;
    @include media('<desktop') {
      font-size: $fs-12;
      height: 15px;
    }
    @include media('<iPhone') {
      font-size: $fs-11;
    }
  }
  &__title{
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: $fs-26;
    color: $c-grey;
    font-weight: $fw-bold;
    max-width: 410px;
    text-align: center;
    padding-top: 2vh;
    @include media('<screen') {
      font-size: $fs-24;
    }
  }
  &__content{
    display: inline-flex;
    flex-direction: column;
    max-width: 410px;
    // width: 100%;
    padding-top: 2vh;
  }
  &__fields{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
  }
  &__item{
    width: 50%;
    display: inline-flex;
    flex-direction: column;
    &:first-child{
      padding-right: 10px;
    }   
    &:last-child{
      padding-left: 10px;
    }
  }
  &__field{
    width: 100%;
    margin-bottom: 2vh;
    font-size: $fs-14;
    font-family: $f-OpenSans;
    @include media('<desktop') {
      font-size: $fs-12;
      margin-bottom: 25px;
    }
    @include media('<phone') {
      margin-bottom: 15px;
    }
    &__label-captcha{
      width: 50%;
    }
  }
  &__captcha{
    display: flex;
    // max-width: 380px;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    cursor: pointer;
    width: 100%;
    &-img{
      width: 40%;
      height: 50px;
    }
  }
  &__button{
    width: 160px;
    height: 50px;
    text-transform: uppercase;
    font-size: $fs-18;
    color: $c-grey;
    border: 2px solid $c-grey;
    background: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance: none;
    font-family: $f-OpenSans;
    @include media('<screen') {
      width: 120px;
      height: 40px;
      font-size: $fs-14;
    }
    &:after {
      background: $c-white;
      content: "";
      height: 110px;
      left: -75px;
      opacity: .2;
      position: absolute;
      top: -30px;
      width: 25px;
      transform: rotate(35deg);
      z-index: 1;
      transition: left 1s cubic-bezier(0.19, 1, 0.22, 1);
    }
    &:focus {
      // border:0;
      outline: 0;
    }
    &:hover{
      cursor: pointer;
      &:after{
        left: 120%;
        
      }
    }
  }
}