// item-factors styles
.m-item-factors{
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  @include media('<maxPhone') {
    display: none;
  }
  &__item{
    height: 115px;
    width: 115px;
    border: 3px solid $c-violet;
    border-radius: 100%;
    position: relative;
    margin-bottom: 15px;
    cursor: pointer;
    overflow: hidden;
    @include media('<screen') {
      height: 90px;
      width: 90px;
      margin: 0 0 10px;
    }
    &-left{
      &:first-child, &:last-child{
        margin-left: 60px;
      }
    }
    &-right{
      &:first-child, &:last-child{
        margin-left: -60px;
      }
    }
  }
  &__img{
    width: 100%;
    height: 100%;
    @include backgroundCoverCenter;
    @include absolutely;
    border-radius: 100%;
  }
}